import { createWebHistory, createRouter } from "vue-router";
import Home               from "@/views/Home.vue";
import Portafolio         from "@/views/Portafolio.vue";
import About              from "@/views/About.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/portafolio",
    name: "Portafolio",
    component: Portafolio,
  },  
  {
    path: "/about",
    name: "About",
    component: About,  
  },
];
	
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;